import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { HashLink as Link } from 'react-router-hash-link';

import '../assets/css/Project.css'
import '../assets/css/Carousel.css'

import projects from '../assets/json/projects.json'

class Project extends React.Component{

    componentDidMount(){
        this.props.visibilitymenufalse()

        setTimeout(function(){
            window.scrollTo(0,0)
        }, 100)
    }

    render(){
        let that = this
        let currentProjectUrl = this.props.location.pathname
        currentProjectUrl = currentProjectUrl.replace('/project/','')
        return(
            <div className="project">
                    {
                        projects.map(function(item, i){
                            if(item.url === currentProjectUrl){
                                return(
                                    <div className="project__info" key={ i }>
                                        <div className="block-header">
                                            <div className="block-header__subtext">
                                                { item.type }
                                            </div>
                                            <div className="block-header__name">
                                                { item.name } { item.squere } м<sup>2</sup>
                                            </div>
                                            <div className="block-header__description">
                                                { item.description }
                                            </div>
                                        </div>
                                        <div className="block project__description">
                                            <div className="in">
                                                <div className="project__gallery">
                                                    {
                                                        item.price
                                                            ?
                                                            <div className="projects__price">
                                                                <div className="projects__price-from">от</div>
                                                                <div className="projects__price-how">{item.price}</div>
                                                                <div className="projects__price-currency">тыс €</div>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                    <AliceCarousel
                                                        fadeOutAnimation={ true }
                                                        fadeInAnimation={ true }
                                                        mouseDragEnabled={ true }
                                                        dotsDisabled={ true }
                                                        autoHeight={ true }
                                                    >
                                                        {
                                                            item.images.map(function(image, k){
                                                                return(
                                                                        <img src={ '/img/projects/' + image } alt={ item.name } key={ k }/>
                                                                )
                                                            })
                                                        }
                                                    </AliceCarousel>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block centered button-block">
                                            {
                                                that.props.location.params
                                                    ?
                                                    <Link className="button button-back" to={ '/#' + that.props.location.params.section }>
                                                        Назад
                                                    </Link>
                                                    :
                                                    <div className="button button-back" onClick={ that.props.history.goBack }>
                                                        Назад
                                                    </div>
                                            }
                                            <div className="small-width"></div>
                                            <Link to={{ pathname: '/order-project', params: {projectUrl: currentProjectUrl} }} className="button button-project">
                                                Заказать проект
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
            </div>
        )
    }
}

export default Project
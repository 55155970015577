import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { animationStart, animationSpeed, animationOffset } from './Constants'
import engineering from '../assets/json/engineering.json'
import '../assets/css/Engineering.css'

class Engineering extends React.Component{
    render(){
        let animation = animationStart
        return(
                <div className="block engineering" id="engineering">
                    <div className="in">
                        <div className="full-left">
                            <div className="block-header block-left">
                                <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                                    Более 140 000 м.кв. жилья в год
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                                    Многофункциональный завод <span className="yellow">Royal Engineering</span>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__description">
                                    Завод по производству стеновых модулей с энергоэффективной системой утепления построен в 2012 году в Киевской области.
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="quote">
                                    Завод работает по конвеерному методу производства.
                                </ScrollAnimation>
                                <div className="engineering__items">
                                        {
                                            engineering.map(function(item,i){
                                                animation =  animation + animationSpeed
                                                return(
                                                    <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="engineering__item" key={ i }>
                                                        <div className="engineering__number">
                                                            <span>{ item.number }</span>
                                                        </div>
                                                        <div className="engineering__name">
                                                            { item.name }
                                                        </div>
                                                    </ScrollAnimation>
                                                )
                                            })
                                        }
                                </div>
                                <a href="https://youtu.be/HTae7zbKNIg" target="_blank" rel="noopener noreferrer" className="button button-brown">
                                    Видео о заводе
                                </a>
                            </div>
                        </div>
                        <div className="full-right">
                                    <ScrollAnimation animateOnce={ true } className="projects__count" animateIn='fadeIn' initiallyVisible={ true } offset={ animationOffset } style={{ opacity:0 }}>
                                        <div>
                                            <div className="projects__count-text">
                                                Реализовано
                                            </div>
                                            <div className="projects__count-number">
                                                518
                                            </div>
                                            <div className="projects__count-text">
                                                проектов
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                <div className="engineering__image engineering__image-first"></div>
                                <div className="engineering__image engineering__image-second"></div>
                                <div className="engineering__image engineering__image-third"></div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Engineering
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { animationOffset } from './Constants'
import '../assets/css/Module.css'

class Module extends React.Component{
    render(){
        return(
            <div className="module">
                <div className="in">
                    <div className="block-header">
                        <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                            Наша продукция
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                            Энергоэффективный стеновой модуль
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__description">
                            Производство сертифицировано по стандартам <span>ISO 9001:2015</span> <span>ISO 14001:2015</span> <span>EN 14992:2007+A1:2012</span>
                        </ScrollAnimation>
                    </div>
                        <div className="module">
                            { this.props.windowSize >= 920
                            ?
                                <ScrollAnimation animateOnce={ true } animateIn='fadeIn' initiallyVisible={ true } style={{ opacity:0 }}>
                                    <img src="/img/module.jpg" alt=""/>
                                </ScrollAnimation>
                            :
                                    <div className="module-description">
                                        <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }}>
                                            <img src="/img/module-mobile.jpg" alt=""/>
                                        </ScrollAnimation>
                                        <div className="module-description-items">
                                            <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="module-description-item">
                                                <div className="module-description-item__number"><span>1</span></div>
                                                <div className="module-description-item__name">
                                                    <div className="module-description-item__header">Металопластиковые окна и двери</div>
                                                    <div className="module-description-item__description">
                                                        ТМ «Rehau» Euro-Design 70. 5-камерный профиль, 2-камерный стеклопакет с UV-защитой и энергосберегающим газом Аргон
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="module-description-item">
                                                <div className="module-description-item__number"><span>2</span></div>
                                                <div className="module-description-item__name">
                                                    <div className="module-description-item__header">Aрхитектурные элементы</div>
                                                    <div className="module-description-item__description">
                                                        Стиль + дополнительная защита стыков панелей
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="module-description-item">
                                                <div className="module-description-item__number"><span>3</span></div>
                                                <div className="module-description-item__name">
                                                    <div className="module-description-item__header">Фасадная отделка</div>
                                                    <div className="module-description-item__description">
                                                        Широкий выбор материалов: фактурных штукатурок, клинкера, натурального или искусственного камня
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="module-description-item">
                                                <div className="module-description-item__number"><span>4</span></div>
                                                <div className="module-description-item__name">
                                                    <div className="module-description-item__header">Система утепления</div>
                                                    <div className="module-description-item__description">
                                                        Состоит из армированного пенополистирола / минеральной ваты
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="module-description-item">
                                                <div className="module-description-item__number"><span>5</span></div>
                                                <div className="module-description-item__name">
                                                    <div className="module-description-item__header">Несущая стена</div>
                                                    <div className="module-description-item__description">
                                                        Армированный бетон
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                            }
                        </div>
                </div>
            </div>
        )
    }
}

export default Module
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { animationOffset } from './Constants'
import '../assets/css/Contacts.css'

class Contacts extends React.Component{
    render(){
        return(
                <div className="contact" id="contacts">
                    <div className="block in">
                        <div className="block-header">
                            <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                                Будем рады Вашему визиту
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                                Контакты
                            </ScrollAnimation>
                        </div>
                        <div className="contact__infos">
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } delay={ 0 } initiallyVisible={ true } style={{ opacity:0 }} className="contact__info">
                                <div className="contact__header">Адрес</div>
                                <div className="contact__text">г. Киев, ул. Московская, 44/1</div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } delay={ 150 } initiallyVisible={ true } style={{ opacity:0 }} className="contact__info">
                                <div className="contact__header">Телефон</div>
                                <div className="contact__text">
                                    <a href="tel:380952350865">38 (098) 539-11-39</a>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } delay={ 300 } initiallyVisible={ true } style={{ opacity:0 }} className="contact__info">
                                <div className="contact__header">Почта</div>
                                <div className="contact__text">danchenko@royal-house.ua</div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } delay={ 450 } initiallyVisible={ true } style={{ opacity:0 }} className="contact__info">
                                <div className="contact__header">Время работы</div>
                                <div className="contact__text">9:00-18:00</div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } delay={ 600 } initiallyVisible={ true } style={{ opacity:0 }} className="contact__info">
                                <a href="https://www.google.com/maps/dir//%D0%9A%D0%BB%D1%83%D0%B1%D0%BD%D0%B8%D0%B9+%D0%B1%D1%83%D0%B4%D0%B8%D0%BD%D0%BE%D0%BA+%22Royal+House%22,+%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%9C%D0%BE%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D1%8C%D0%BA%D0%B0,+44%2F1,+%D0%9A%D0%B8%D1%97%D0%B2,+02000/@50.3961428,30.4541192,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40d4c91ce4e2ccd9:0x5314a3731b8cdd88!2m2!1d30.4536093!2d50.3845548!3e2" target="_blank" rel="noopener noreferrer" className="button button-yellow">
                                    Проложить маршрут
                                </a>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Contacts

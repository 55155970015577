import React from 'react'
import Menu from './Menu'
import {Link} from 'react-router-dom'
import '../assets/css/Header.css'
import Scrollchor from 'react-scrollchor';

import logo from '../assets/img/logo.svg'
import phone from '../assets/img/phone.svg'
import menu from '../assets/img/menu.svg'

class Header extends React.Component {

    constructor() {

        super()

        this.state = {
            visMenu: ''
        }

        this.visMenuHandler = this.visMenuHandler.bind(this)

    }

    visMenuHandler() {
        if (this.state.visMenu !== ' vis') {
            this.setState({
                visMenu: ' vis'
            })
        } else {
            this.setState({
                visMenu: ''
            })
        }
    }

    render() {
        return (
            <div className="header" id="header">
                {
                    this.props.location.pathname === '/'
                    ?
                        <Scrollchor to={ '#entry' } animate={{ duration: 500 }} className="logo">
                            <img src={ logo } alt="Royal House" className="logo"/>
                        </Scrollchor>
                        :
                        <Link to="/" className="logo">
                            <img src={ logo } alt="Royal House" className="logo"/>
                        </Link>
                }
                <Menu visibilityMenu={ this.props.visibilityMenu } visMenuState={ this.state.visMenu } visMenu={ this.visMenuHandler }/>
                <div className="contacts">
                    <div className="phone">
                        <img src={ phone } alt=""/>
                        <span><a href="tel:0985391139">(098) 539 11 39</a></span>
                    </div>
                    <Link to='/call-back'
                          className="button button-brown"
                          onClick={ this.props.visibilityMenuFalse }
                    >
                        Заказать звонок
                    </Link>
                    {
                        this.props.visibilityMenu ?
                            <div className="burger" onClick={ this.visMenuHandler }>
                                <img src={ menu } alt="Меню"/>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default Header
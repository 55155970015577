import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'babel-polyfill';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WZWWWLK'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <BrowserRouter>
            <App />
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import Scrollchor from 'react-scrollchor';

import menu from '../assets/json/menu.json'

class Menu extends React.Component {
    render() {
        let that = this
        return (
            <div className={'menu ' + this.props.visMenuState }>
                {
                    this.props.visibilityMenu
                        ?
                        menu.map(function (item, i) {
                            return (
                                <div className="menu__item " key={ i } onClick={ that.props.visMenu }>
                                <Scrollchor
                                    to={ '#' + item.url }
                                    animate={{offset: -80, duration: 500}}
                                >
                                    { item.name }
                                </Scrollchor>
                                </div>
                            )
                        })
                        :
                        null
                }
            </div>
        )
    }
}

export default Menu
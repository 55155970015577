import React from 'react'
import { Link } from 'react-router-dom'
import projects from '../assets/json/projects.json'
import { animationStart,  animationSpeed, animationOffset } from './Constants'
import ScrollAnimation from 'react-animate-on-scroll';

import '../assets/css/Projects.css'

import look from '../assets/img/look.svg'

class Projects extends React.Component{
    render(){
        let animation = animationStart
        return(
                <div className="block projects" id="projects">
                    <div className="in">
                        <div className="block-header">
                            <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                                ROYAL DEVELOPMENT
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                                Проекты
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__description">
                                Дом Вашей мечты
                            </ScrollAnimation>
                        </div>
                        <div className="projects__list">
                            {
                                projects.map(function(item, i){
                                    animation = animation +  animationSpeed
                                    return(
                                        <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} delay={ animation } className="projects__item" key={ i }>
                                            <Link to={{ pathname: '/project/' + item.url, params: {section: 'projects'} }} >
                                                <div className="projects__image" style={{ backgroundImage: `url(${ '/img/projects/' + item.catalogImage})` }}>
                                                    <div className="projects__name">
                                                        { item.name } - { item.squere } м<sup>2</sup>
                                                    </div>
                                                    {
                                                        item.price
                                                            ?
                                                            <div className="projects__price">
                                                                <div className="projects__price-from">от</div>
                                                                <div className="projects__price-how">{item.price}</div>
                                                                <div className="projects__price-currency">тыс €</div>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                    <div className="projects__look">
                                                        <img src={ look } alt=""/>
                                                    </div>
                                                </div>
                                            </Link>
                                        </ScrollAnimation>
                                    )
                                })
                            }
                        </div>
                        <div className="centered">
                            <Link to={{ pathname: '/calculate', params: {section: 'projects'} }}
                                  className="button button-brown"
                            >
                                Рассчитать проект
                            </Link>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Projects
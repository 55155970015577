import React from 'react'

import Menu from './Menu'

import '../assets/css/Footer.css'

import logo from '../assets/img/logo.svg'
import adcore from '../assets/img/adcore.svg'

import facebook from '../assets/img/facebook.svg'

class Footer extends React.Component{
    render(){
        return(
            <div className="footer">
                <div className="in">
                    <div className="footer__content">
                        <div className="logo">
                            <img src={ logo } alt="Royal House"/>
                        </div>
                        <Menu visibilityMenu={ this.props.visibilityMenu }/>
                        <div className="socials">
                            <a href="https://www.facebook.com/Royal-House-1412286185696953/" rel="noopener noreferrer" target="_blank" className="socials-facebook">
                                <img src={ facebook } alt="Royal House в Facebook"/>
                            </a>
                        </div>
                    </div>
                    <div className="footer__copyright">
                        <div className="year">
                            Все права защищены © { (new Date()).getFullYear() }. Сайт разработан по заказу ООО "Роял Инжиниринг".
                        </div>
                        <div className="made">
                            <a href="https://adcore.ua" rel="noopener noreferrer" target="_blank" className="adcore">
                                <img src={ adcore } alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
import React from 'react'
import {  Link } from 'react-router-dom'
import '../assets/css/Entry.css'

class Entry extends React.Component{
    render(){
        return(
            <div className="entry" id="entry">
                        <div className="entire-text">
                            <div className="entire-text__slogan">
                                Готовые стеновые модули для Вашего дома
                            </div>
                            <div className="entire-text__subslogan">
                                Royal House – компания полного цикла, которая осуществляет проектирование зданий, изготовление, доставку и монтаж стеновых модулей
                            </div>
                            <Link to={{ pathname: '/expert-consultation', params: {section: 'entry'} }} className="button button-yellow">
                                Консультация эксперта
                            </Link>
                        </div>
                <div className="next">
                    <a className="scroll-link" href="#content">
                        <svg className="mouse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 130" preserveAspectRatio="xMidYMid meet">
                            <g fill="none" fillRule="evenodd">
                                <rect width="70" height="118" x="1.5" y="1.5" stroke="#FFF" strokeWidth="3" rx="36"/>
                                <circle className="scroll" cx="36.5" cy="31.5" r="4.5" fill="#FFF"/>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
        )
    }
}

export default Entry
import React from 'react'
import InputMask from 'react-input-mask'
import { HashLink as Link } from 'react-router-hash-link';
import '../assets/css/Feedback.css'
import TagManager from 'react-gtm-module'

import close from '../assets/img/close.svg'

class Feedback extends React.Component {

    constructor() {
        super()

        this.state = {
            name: '',
            email: '',
            phone: '',
            errorName: '',
            errorPhone: '',
            errorEmail: '',
            projectUrl: '',
            showSubmitButton: true,
            successSendText: 'Ваша завка успешно отправлена'
        }

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.handleChangePhone = this.handleChangePhone.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.props.visibilitymenufalse()

        // setTimeout('window.scrollTo(0,0)', 50)

        setTimeout(function(){
            window.scrollTo(0,0)
        }, 100)

        if(this.props.location.params){
            this.setState({
                projectUrl: '/project/' + this.props.location.params.projectUrl
            })
        }

    }

    handleChangeName(event) {
        this.setState({
            name: event.target.value,
            errorName: ''
        });
    }

    handleChangeEmail(event) {
        this.setState({
            email: event.target.value,
            errorEmail: ''
        });
    }

    handleChangePhone(event) {
        this.setState({
            phone: event.target.value,
            errorPhone: ''
        });
    }

    handleSubmit() {

        const mailChecker = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.name.length < 2) {
            this.setState({
                errorName: 'error'
            })
            return false
        } else {
            this.setState({
                errorName: ''
            })
        }

        if (!mailChecker.test(this.state.email)) {
            this.setState({
                errorEmail: 'error'
            })
            return false
        } else {
            this.setState({
                errorEmail: ''
            })
        }

        if (this.state.phone.length < 18 || this.state.phone.includes('_')) {
            this.setState({
                errorPhone: 'error'
            })
            return false
        } else {
            this.setState({
                errorPhone: ''
            })
        }

        this.setState({
            showSubmitButton: false
        })

        const tagManagerArgs = {
            dataLayer: {
                event: 'form',
            }
        }

        TagManager.dataLayer(tagManagerArgs)

        fetch('/request/request.php', {
            mode: 'no-cors',
            method: 'POST',
            body: JSON.stringify({name: this.state.name, email: this.state.email, phone: this.state.phone, header: this.props.header, projecturl: this.state.projectUrl })
        })
            .then((responseData) => { console.log("response: " + responseData); })
            .catch((err) => { console.log(err); });

    }

    render() {
        let that = this
        return (
            <div className="feedback">
                <div className="in">
                        <div className="block feedback__form">
                            {
                                that.props.location.params
                                ?
                                    <Link className="close" to={ '/#' + that.props.location.params.section }>
                                        <img src={ close } alt="Закрыть"/>
                                    </Link>
                                    :
                                    <div onClick={ that.props.history.goBack } className="close" >
                                        <img src={ close } alt="Закрыть"/>
                                    </div>
                            }

                            <div className="block-header">
                                <div className="block-header__name">
                                    { this.props.header }
                                </div>
                                <div className="block-header__description">
                                    Введите Ваши данные и наши специалисты свяжутся с Вами в ближайшее время
                                </div>
                            </div>
                            <div className="form">
                                <form>
                                    <div className="nameInput">
                                        <input name="name" type="text" placeholder="Имя" value={ this.state.value }
                                               onChange={ this.handleChangeName } className={ this.state.errorName }/>
                                    </div>
                                    <div className="inputEmail">
                                        <input name="email" type="email" placeholder="E-mail" value={ this.state.email }
                                               onChange={ this.handleChangeEmail }
                                               className={ this.state.errorEmail }></input>
                                    </div>
                                    <div className="inputPhone">
                                        <InputMask mask="3\8 (999) 999-99-99" name="phone"
                                                   placeholder="38 (0XX) XXX-XX-XX" value={ this.props.phone }
                                                   onChange={ this.handleChangePhone }
                                                   className={ this.state.errorPhone }/>
                                    </div>
                                    <div className="centered">
                                        {
                                            this.state.showSubmitButton
                                                ?
                                                <div className="button button-yellow" onClick={ this.handleSubmit }>
                                                    Отправить
                                                </div>
                                                :
                                                <div className="centered send_success">
                                                    👌 { this.state.successSendText }
                                                </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

export default Feedback
import React from 'react'
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import workType from '../assets/json/work-type.json'
import { animationStart,  animationSpeed, animationOffset } from './Constants'

import '../assets/css/WorkType.css'

class WorkType extends React.Component{

    render(){
        let animation = animationStart
        return(
            <div className="block work-type" id="worktype">
                    <div className="block-header">
                        <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                            Процесс
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                            Три этапа
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__description">
                            возведения капитальных стен Вашего дома
                        </ScrollAnimation>
                    </div>
                    <div className="work-type__list">
                        <div className="in">
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' className="work-lines" delay={ 1000 } ></ScrollAnimation>
                            <div className="in-s">
                        {
                            workType.map(function(item,i){
                                animation = animation +  animationSpeed
                                return(
                                    <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="work-type__item" key={ i } delay={ animation }>
                                        <div className="work-type__number">
                                            <span>{ item.number }</span>
                                        </div>
                                        <div className="work-type__name">
                                            { item.name }
                                        </div>
                                        <div className="work-type__stage">
                                            {
                                                item.stages.map(function(stage,i){
                                                    return(
                                                    <div className="work-type__stage-item" key={ i }>
                                                            {stage}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ScrollAnimation>
                                )
                            })
                        }
                        </div>
                        </div>
                    </div>
                <div className="centered">
                    <Link to={{ pathname: '/cooperation', params: {section: 'worktype'} }}
                          className="button button-brown"
                    >
                        Начать сотрудничество
                    </Link>
                </div>
            </div>
        )
    }
}

export default WorkType
import React from 'react'
import ReactMapboxGl, { Marker, ZoomControl } from "react-mapbox-gl";
import marker from '../assets/img/marker.svg'
import newEngland from '../assets/img/newengland.svg'
import britanskiyKvartal from '../assets/img/britanskiy.svg'

class Map extends React.Component{

    render(){

        const Map = ReactMapboxGl({
            accessToken: "pk.eyJ1Ijoicm95YWwtaG91c2UiLCJhIjoiY2pxeGhyenlkMWg2NDQ5bWhhc2tlYjlxYyJ9.b_Oj-SseI5EQKMilylS9qw",
            scrollZoom: false,
            showCompass: true,
            dragPan: false,
            interactive: true
        });

        return(
            <div className="map">
                <Map
                    style="mapbox://styles/royal-house/cjqxi525w13t52slrewi1od8p"
                    center={[30.453611, 50.384551]}
                    zoom={[ 12 ]}
                    bearing={[ 3 ]}
                    containerStyle={{
                        height: "400px",
                        width: "100%"
                    }}
                    scrollZoom={ false }
                    touchZoom={ true }
                    >
                    <ZoomControl />
                    <Marker
                        coordinates={[30.453611, 50.384551]}
                        anchor="bottom">
                        <img src={ marker } alt="Royal House"/>
                    </Marker>
                    <Marker
                        coordinates={[30.473725, 50.392662]}
                        anchor="bottom">
                        <img src={ newEngland } alt="ЖК Новая Англия"/>
                    </Marker>
                    <Marker
                        coordinates={[30.447371, 50.386123]}
                        anchor="bottom">
                        <img src={ britanskiyKvartal } alt="ЖК Британский квартал"/>
                    </Marker>
                </Map>
            </div>
        )
    }
}

export default Map
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { animationStart,  animationSpeed, animationOffset } from './Constants'
import '../assets/css/Benefits.css'

import benefits from '../assets/json/benefits.json'

class Benefits extends React.Component{
    render(){
        let animation = animationStart
        return(
                    <div className="block benefits">
                        <div className="in animatedParent">
                            {
                                benefits.map(function (item, i) {
                                    animation = animation +  animationSpeed
                                    return (
                                            <ScrollAnimation animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} animateOnce={ true } delay={animation} className="benefit" key={ i } >
                                                <div className="benefit__name">
                                                    { item.name }
                                                </div>
                                                <div className="benefit__description">
                                                    { item.description }
                                                </div>
                                            </ScrollAnimation>
                                    )
                                })
                            }
                        </div>
                    </div>
        )
    }
}

export default Benefits
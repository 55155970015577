import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { Link } from 'react-router-dom'
import Lightbox from 'react-images';
import { animationStart, animationSpeed, animationOffset} from './Constants'
import '../assets/css/Certificate.css'
import '../assets/css/Carousel.css'
import certificates from '../assets/json/certificates.json'

import ScrollAnimation from 'react-animate-on-scroll';

let certificateImages = []

certificates.forEach(function(item){
    certificateImages.push({ src: item.file })
})

class Certificates extends React.Component{

    constructor(){
        super();
        this.state = {
            lightboxIsOpen: false,
            currentImage: 0
        }

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: event,
            lightboxIsOpen: true,
        });
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render(){


        const responsive = {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            920: {
                items: 2
            },
            1024: {
                items: 3
            }
        };

        let that = this
        let animation = animationStart
        return(
                <div className="block certificates" id="certificates">
                    <Lightbox images={ certificateImages }
                              onClose={this.closeLightbox}
                              onClickPrev={this.gotoPrevious}
                              onClickNext={this.gotoNext}
                              currentImage={this.state.currentImage}
                              isOpen={this.state.lightboxIsOpen}
                              backdropClosesModal={ true }
                              showImageCount={false}
                              clickOutsideToClose={ true }
                              wrapperClassName={ 'modal-certificates' }
                    />
                    <div className="in">
                        <div className="block-header">
                            <ScrollAnimation animateOnce={ true } animateIn='fadeInDown' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__subtext">
                                Сертификаты, патенты, награды
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__name">
                                Наши достижения
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={ true } animateIn='fadeIn' offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="block-header__description">
                                Международные сертификаты качества и успешное прохождение аудитов производства на заводе «Royal Engineering» подтверждают соответствие нашей продукции Европейским стандартам и открывают доступ стеновым модулям ТМ Royal House на рынки Европы.
                            </ScrollAnimation>
                        </div>
                        <AliceCarousel
                            responsive={responsive}
                            fadeOutAnimation={true}
                            mouseDragEnabled={true}
                            dotsDisabled={true}
                        >
                            {
                                certificates.map(function(item, i){
                                    animation = animation +  animationSpeed
                                    return (
                                        <ScrollAnimation animateOnce={ true } animateIn='fadeInUp' delay={ animation } offset={ animationOffset } initiallyVisible={ true } style={{ opacity:0 }} className="certificate-item" key={ i } >
                                                <div className="certificate-item__in">
                                                    <img src={ item.file } alt={item.name} onClick={() => that.openLightbox(i) }/>
                                                </div>
                                        </ScrollAnimation>
                                    )
                                })
                            }
                        </AliceCarousel>
                        <div className="centered">
                            <Link to={{ pathname: '/ask-question', params: {section: 'certificates'} }}
                                  className="button button-yellow"
                            >
                                Задать вопрос
                            </Link>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Certificates
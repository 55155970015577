import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import Entry from './Entry'
import Contacts from './Contacts'
import Certificates from './Certificates'
import Engineering from './Engineering'
import Benefits from './Benefits'
import WorkType from './WorkType'
import Projects from './Projects'
import Map from './Map'
import Module from './Module'

class Main extends Component {

    componentDidMount() {
        this.props.visibilityMenuTrue()
    }

    render() {
        return (
            <div className="main">
                <Entry />
                <Benefits />
                    <Engineering />
                    <Module windowSize={ this.props.windowSize }/>
                    <Certificates />
                    <WorkType />
                    <Projects />
                    <Contacts />
                    <Map />
            </div>
        );
    }
}

export default withRouter(Main);

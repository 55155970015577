import React, {Component} from 'react';
import './assets/css/App.css';
import "animate.css/animate.min.css";

import { Switch, Route, withRouter } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Main from './components/Main'
import Project from './components/Project'
import Feedback from './components/Feedback'
import Loading from './components/Loading'

class App extends Component {

    constructor(){
        super();

        this.state={
            menu: true,
            projectUrl: '',
            loading: true,
            windowSize: ''
        }

        this.handlerMenuClickFalse = this.handlerMenuClickFalse.bind(this)
        this.handlerMenuClickTrue = this.handlerMenuClickTrue.bind(this)
        this.handlerScrollTop = this.handlerScrollTop.bind(this)
        this.handleLoad = this.handleLoad.bind(this);
        this.resizeHandle = this.resizeHandle.bind(this)
    }

    handlerMenuClickFalse(){
        if (this.state.menu === true) {
            this.setState({
                menu: false
            })
        }
    }

    handlerMenuClickTrue(){
        if (!this.state.menu) {
            this.setState({
                menu: true
            })
        }
    }

    handlerScrollTop(){
            window.scrollTo(0, 0)
    }

    componentDidMount(){
        window.addEventListener('load', this.handleLoad);
        window.addEventListener("resize", this.resizeHandle);
        this.resizeHandle();

    }

    resizeHandle() {
        this.setState({
            windowSize: window.innerWidth});
    }

    handleLoad() {
        this.setState({
            loading: false
        })
    }


    render() {
        console.log(this.props);
        return (
            <div className="wrapper">
                {
                    this.state.loading
                        ?
                        <Loading status='loading'/>
                        :
                        <Loading status='loading disable'/>
                }
                <Header {...this.props} visibilityMenu={ this.state.menu }/>
                <Switch>
                    <Route exact path='/' component={ () => <Main windowSize={ this.state.windowSize } visibilityMenuTrue={ this.handlerMenuClickTrue }/> }/>
                    <Route exact path='/call-back' component={ ()=> <Feedback {...this.props} header="Заявка на обратный звонок" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route exact path='/expert-consultation' component={ ()=> <Feedback {...this.props} header="Заявка на консультацию эксперта" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route exact path='/ask-question' component={ ()=> <Feedback {...this.props} header="Задать вопрос" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route exact path='/cooperation' component={ ()=> <Feedback {...this.props} header="Начать сотрудничество" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route exact path='/calculate' component={ ()=> <Feedback {...this.props} header="Рассчитать проект" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route exact path='/order-project' component={ ()=> <Feedback {...this.props} header="Заказать проект" visibilitymenufalse={ this.handlerMenuClickFalse } /> }/>
                    <Route path='/project/' component={ () => <Project {...this.props} visibilitymenufalse={ this.handlerMenuClickFalse }/> }/>
                </Switch>
                <Footer visibilityMenu={ this.state.menu }/>
                <svg style={{ visibility: 'hidden', position: 'absolute' }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                        <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                        </filter>
                    </defs>
                </svg>
            </div>
        );
    }
}

export default withRouter(App);

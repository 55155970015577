import React from 'react'
import logo from '../assets/img/logo.svg'
import '../assets/css/Loading.css'

class Loading extends React.Component{
    render(){
        let loadingStatus = this.props.status
    return(
        <div className={ 'loads ' + loadingStatus } >
            <img src={ logo } alt="Royal House" className="logo-loading"/>
        </div>
    )
}
}

export default Loading